import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Footer = dynamic(() => import('./widgets/footer'))
const Header = dynamic(() => import('./widgets/header'))
const Banner = dynamic(() => import('./widgets/banner'))
const Content = dynamic(() => import('./widgets/content'))
const Section = dynamic(() => import('./widgets/section'))
const SectionHead = dynamic(() => import('./widgets/section-head'))
const ProductionList = dynamic(() => import('./widgets/production-list'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Image = dynamic(() => import('./base/image'))
const ContactForm = dynamic(() => import('./widgets/contact-form'))
const OurProducts = dynamic(() => import('./widgets/our-products'))
const Catalogs = dynamic(() => import('./widgets/catalogs'))

export default function getter(name: string) {
  switch (name) {
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'container':
      return Container
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'narin-header':
      return Header
    case 'footer':
      return Footer
    case 'narin-banner':
      return Banner
    case 'narin-content':
      return Content
    case 'narin-section':
      return Section
    case 'narin-section-head':
      return SectionHead
    case 'narin-production-list':
      return ProductionList
    case 'narin-form':
      return ContactForm
    case 'narin-our-products':
      return OurProducts
    case 'narin-catalogs':
      return Catalogs
  }
}
