import React, { createContext, useContext, useState } from 'react'
import { SectionContextType } from './types'

const SectionContext = createContext<SectionContextType | undefined>(undefined)

export const SectionProvider: React.FC = ({ children }) => {
  const [currentSection, setCurrentSection] = useState<string>('')

  return (
    <SectionContext.Provider value={{ currentSection, setCurrentSection }}>
      {children}
    </SectionContext.Provider>
  )
}

export const useSection = (): SectionContextType => {
  const section = useContext(SectionContext)

  if (section) {
    return section
  }

  throw new Error('useApp must be used within a AppContextProvider.')
}
